export default {
  'java': 
    `import java.util.*;

public class Solution {
    public static void main(String[] args) {
        // Write your code here.
    }
}`,

  'python':
    `import math
from collections import defaultdict, deque
import heapq

def main():
    # Write your code here.
    pass

if __name__ == "__main__":
    # This will be executed when ran.
    main()
`,

  'c_cpp':
    `#include <iostream>
using namespace std;
  
int main() {
    // Write your code here.
    return 0;
}`
};